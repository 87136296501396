import * as S from "./styles";

const ServicesRange = () => (
  <S.SectionWrapper>
    <S.SectionTitle id="zakres-uslug">Zakres usług</S.SectionTitle>

    <S.ParagraphWithFullWidth>Biuro projektowe AK+ specjalizuje się w sporządzaniu kompletnej dokumentacji technicznej w branży konstrukcyjno-budowlanej i inżynierskiej.</S.ParagraphWithFullWidth>
    <S.ParagraphWithMargin>Wykonujemy opracowania projektowe we wszystkich stadiach:</S.ParagraphWithMargin>
    <S.List>
        <S.ListItem>koncepcje, projekty budowlane i wykonawcze </S.ListItem>
        <S.ListItem>projekty branżowe konstrukcyjno-budowlane i inżynierskie </S.ListItem>
        <S.ListItem>rozbudowy, nadbudowy i przebudowy budynków</S.ListItem>
        <S.ListItem>okresowe przeglądy techniczne budynków</S.ListItem>
        <S.ListItem>ekspertyzy i opinie techniczne</S.ListItem>
        <S.ListItem>doradztwo techniczne </S.ListItem>
        <S.ListItem>adaptacje poddaszy </S.ListItem>
        <S.ListItem>koncepcje projektowe </S.ListItem>
        <S.ListItem>inwentaryzacje budynków </S.ListItem>
        <S.ListItem>wzmocnienia konstrukcji </S.ListItem>
    </S.List>

    <S.ParagraphWithMargin>Architektom polecamy swoje usługi w zakresie projektowania wszelkich obiektów w branży konstrukcyjnej.</S.ParagraphWithMargin>

    <S.ParagraphWithMargin>Oferujemy fachową pomoc i doradztwo techniczne.</S.ParagraphWithMargin>
  </S.SectionWrapper>
);

export default ServicesRange;
