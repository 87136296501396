import * as S from "./styles";
import LogoBig from "../../Logo_big.png";

const AboutCompany = () => (
  <S.SectionWrapper>
    
    <S.ImageWrapper>
      <img src={LogoBig} width="30%"/>
    </S.ImageWrapper>
    <S.SectionTitle id="o-firmie">O firmie</S.SectionTitle>
    <S.Paragraph>Głównym obszarem działalności, którym zajmuje się „Biuro AK+”  jest projektowanie konstrukcji budowlanych. </S.Paragraph>
        <S.Paragraph>Powierzone nam zadania traktujemy z odpowiednią starannością i odpowiedzialnością, gdyż na naszych barkach spoczywa zapewnienie bezpieczeństwa użytkowników obiektu budowlanego.</S.Paragraph>
        <S.Paragraph>Jednocześnie naszym priorytetem jest stawianie sobie za cel stosowanie optymalnych rozwiązań projektowych, skutkujących minimalnym zużyciem materiałów, przy zapewnieniu wymagań wytrzymałościowych.</S.Paragraph>
  </S.SectionWrapper>
);

export default AboutCompany;
