import * as S from "./styles";

const Offer = () => (
  <S.SectionWrapper>
    <S.SectionTitle id="oferta">Oferta</S.SectionTitle>

    <S.Paragraph>Uczestniczymy w procesie projektowym od fazy koncepcji aż do projektu wykonawczego, w którym wykorzystujemy zaawansowane oprogramowanie, które przyspiesza naszą pracę, co z kolei skutkuje uzyskaniem optymalnych rozwiązań konstrukcji: żelbetowych, drewnianych, murowanych, stalowych oraz mieszanych. </S.Paragraph>
    <S.ParagraphWithMargin>Nasze Biuro specjalizuje się między innymi w pracach projektowych dla następujących obiektów:</S.ParagraphWithMargin>
    <S.List>
        <S.ListItem>budynki gospodarcze oraz garażowe</S.ListItem>
        <S.ListItem>budynki rekreacji indywidualnej</S.ListItem>
        <S.ListItem>budynki mieszkalne jednorodzinne i wielorodzinne</S.ListItem>
        <S.ListItem>budynki usługowe i użyteczności publicznej</S.ListItem>
        <S.ListItem>budynki biurowe</S.ListItem>
        <S.ListItem>obiekty: sportowe, przemysłowe</S.ListItem>
        <S.ListItem>hale produkcyjne oraz magazynowe</S.ListItem>
        <S.ListItem>konstrukcje oporowe </S.ListItem>
        <S.ListItem>adaptacja projektów gotowych </S.ListItem>
        <S.ListItem>inwentaryzacje budynków istniejących</S.ListItem>
        <S.ListItem>modernizacje i rozbudowy budynków istniejących</S.ListItem>
        <S.ListItem>projekty zamienne do pozwolenia na budowę</S.ListItem>
        <S.ListItem>projekty zmiany sposobu użytkowania</S.ListItem>
        <S.ListItem>rozbiórki budynków oraz budowli</S.ListItem>
    </S.List>
  </S.SectionWrapper>
);

export default Offer;
