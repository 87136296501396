import styled from "styled-components";
import { SectionWrapper } from "../sections/styles";

export const AppWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(245, 245, 245, 0.6);
`;

export const ContentWrapper = styled.div`
    width: 100%
    z-index: 2;
    background: white;
    
    padding-bottom: 50px;

    ${SectionWrapper}:last-of-type {
        margin: 0;
    }

    @media (min-width: 768px) {
        width: 66%;
        margin: auto;
    }
`;
