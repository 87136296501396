import * as S from "./Navbar.styles";
import Logo from "../../assets/Logo_small.png";

const Navbar = (props) => (
  <S.NavbarWrapper>
    <S.LogoLink href="#"><img src={Logo} height={24} /></S.LogoLink>
    <S.LinksWrapper>
      <S.Link href="#o-firmie">O firmie</S.Link>
      <S.Link href="#oferta">Oferta</S.Link>
      <S.Link href="#zakres-uslug">Zakres usług</S.Link>
      <S.Link href="#kontakt">Kontakt</S.Link>
    </S.LinksWrapper>
  </S.NavbarWrapper>
);

export default Navbar;
