import Navbar from "../Navbar/Navbar";
import { AppWrapper, ContentWrapper } from "./App.styles";
import Image1 from "../../assets/image_1.jpg";
import Image2 from "../../assets/image_2.jpg";
import Image4 from "../../assets/image_4.jpg";
import AboutCompany from "../sections/AboutCompany";
import Offer from "../sections/Offer";
import Contact from "../sections/Contact";
import ServicesRange from "../sections/ServicesRange";
import "./App.css";
import Footer from "../Footer/Footer";

const App = () => (
  <>
    <AppWrapper>
      <Navbar />
      <ContentWrapper>
        <AboutCompany />
        <img src={Image2} width="100%" />
        <Offer />
        <img src={Image1} width="100%" />
        <ServicesRange />
        <img src={Image4} width="100%" />
        <Contact />
      </ContentWrapper>
    </AppWrapper>
    <Footer />
  </>
);

export default App;
