import styled from "styled-components";

export const FooterWrapper = styled.div`
  text-align: center;
  background-color: white;
`;

export const UpperFooterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0;
  background: rgb(245, 245, 245, 1);

  @media (min-width: 580px) {
    flex-direction: row;
    align-items: center;
  justify-content: center;
  }
`;

export const HR = styled.hr`
    color: rgb(245, 245, 245, 0.9);
    width: 20%;
`;

export const BottomFooterSection = styled.div`
    padding: 15px 0;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 580px) {
    flex-direction: row;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: black;
  padding: 4px 20px;
`;

export const LogoLink = styled.a`
  text-decoration: none;
  color: black;
  
  background: rgb(245, 245, 245, 0.9);
  padding: 10px 30px;

  @media (min-width: 580px) {
    border-right: 1px solid black;
    padding: 0 30px;
  }
`;
