import * as S from "./styles";

const Contact = () => (
  <S.SectionWrapper>
    <S.SectionTitle id="kontakt">Kontakt</S.SectionTitle>

    <S.Paragraph>„AK+ Biuro Projektowe Kamil Kurzyniec”</S.Paragraph>
    <S.Paragraph>NIP: 5512380422</S.Paragraph>
    <S.Paragraph>Tel: +48 781-554-151</S.Paragraph>
    <S.Paragraph>Email: <a href="mailto:biuro.akplus@wp.pl">biuro.akplus@wp.pl</a></S.Paragraph>

    <S.ParagraphWithMargin>Godziny pracy:</S.ParagraphWithMargin>
    <S.Paragraph>Poniedziałek – Piątek:  9.00-17.00</S.Paragraph>
    <S.Paragraph>Sobota – Niedziela: nieczynne</S.Paragraph>
    <S.Paragraph>Możliwość spotkań poza ustalonymi godzinami pracy po wcześniejszym ustaleniu.</S.Paragraph>
  </S.SectionWrapper>
);

export default Contact;
