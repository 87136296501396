import styled from "styled-components";

export const NavbarWrapper = styled.header`
  width: calc(100% - 80px);
  height: 50px;
  box-shadow: rgb(0 0 0 / 64%) 0px 2px 8px -5px;
  display: flex;
  align-items: center;
  background: white;
  margin-bottom: 2px;
  position: sticky;
  top: 0;
  left: 0;
  padding: 0 40px;
`;

export const LinksWrapper = styled.div`
  display: none;
  @media (min-width: 580px) {
    display: block;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: black;
  padding: 4px 20px;
`;

export const LogoLink = styled.a`
  text-decoration: none;
  color: black;
`;
