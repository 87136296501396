import * as S from "./Footer.styles";
import LogoBig from "../../Logo_big_grey.png";

const Footer = () => (
  <S.FooterWrapper>
    <S.UpperFooterSection>
      <S.LogoLink href="#">
        <img src={LogoBig} height={100} />
      </S.LogoLink>
      <S.LinksWrapper>
        <S.Link href="#o-firmie">O firmie</S.Link>
        <S.Link href="#oferta">Oferta</S.Link>
        <S.Link href="#zakres-uslug">Zakres usług</S.Link>
        <S.Link href="#kontakt">Kontakt</S.Link>
      </S.LinksWrapper>
      
    </S.UpperFooterSection>
    <S.BottomFooterSection>
      © 2021 by AK+ Biuro Projektowe Kamil Kurzyniec
    </S.BottomFooterSection>
  </S.FooterWrapper>
);

export default Footer;
