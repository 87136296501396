import styled from "styled-components";

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
`;

export const SectionTitle = styled.h2`
  padding-top: 60px;
  font-size: 40px;
  text-align: center;
`;

export const Paragraph = styled.p`
  width: 80%;
  line-height: 1.5;
  margin: 4px 0;
  text-align: center;
`;

export const ParagraphWithFullWidth = styled.p`
  width: 100%;
  line-height: 1.5;
  margin: 4px 0;
  text-align: center;
`;

export const ParagraphWithMargin = styled.p`
  width: 80%;
  line-height: 1.5;
  margin: 20px 0 4px 0;
  text-align: center;
`;

export const ImageWrapper = styled.div`
  text-align: center;
`;

export const ListItem = styled.li`
  line-height: 1.5;
`;

export const List = styled.ul`
  margin: 0;
`;
